<template>
  <div class="surveys-stats">
    <h3 class="surveys-stats-item">
      Total: {{ currentTotal }}
    </h3>
    <h3 class="surveys-stats-item">
      To Review: {{ currentConflicts }}
    </h3>
    <h3 class="surveys-stats-item">
      Reviewed: {{ reviewedConflicts }}
    </h3>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import attributions from './data/attributions';

  export default {
    name: 'Surveys_SummaryStats',
    data: () => ({
      attributions,
    }),
    computed: {
      ...mapGetters('surveys', ['surveys', 'isLoading']),
      currentTotal() {
        return this.surveys.length;
      },
      currentConflicts() {
        return this.surveys.filter((survey) => this.highlight(survey)).length;
      },
      reviewedConflicts() {
        return this.surveys.filter((survey) => survey.status === 'reviewed').length;
      },
    },
    methods: {
      highlight(survey) {
        return !['completed', 'reviewed'].includes(survey.status) || survey.attribution === 'Unknown' || survey.attribution === 'UTM';
      },
    },

  };
</script>

<style scoped lang="scss">
  .surveys-stats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;

    &-item {
      text-align: left;
      margin: 5px 10px;
    }
  }
</style>
