<template>
  <div class="surveys-modal">
    <div class="surveys-modal-container">
      <ButtonAction
        v-for="attr in attributions"
        :key="attr.label"
        class="surveys-modal-button"
        :secondary="attr.label !== attribution"
        @click.native="update(attr)">
        <font-awesome-icon
          class="surveys-modal-button-icon"
          :icon="attr.logo" />
        {{ attr.label }}
      </ButtonAction>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import attributions from './data/attributions';

  export default {
    name: 'Surveys_Modal',
    components: {
      ButtonAction,
    },
    props: {
      attribution: {
        type: String,
        default: '',
      },
      externalId: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      attributions,
    }),
    methods: {
      ...mapActions('surveys', ['updateAttribution']),
      async update(attr) {
        this.$emit('close');
        await this.updateAttribution({
          extId: this.externalId,
          attribution: attr.label,
          status: 'reviewed',
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .surveys-modal {
    margin: 20px;

    &-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    &-button {
      flex: 0 180px;
      margin: 10px;
      padding: 10px 16px;
      border-radius: 50px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      &-icon {
        margin: 0 10px;
      }
    }
  }
</style>
