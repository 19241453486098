<template>
  <div class="surveys-date-selectors">
    <ButtonAction
      v-for="interval in intervals"
      :key="interval"
      :secondary="interval !== selectedInterval"
      class="surveys-date-selectors-button"
      @click.native="changeInterval(interval)">
      {{ interval }}
    </ButtonAction>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import ButtonAction from '@/shared/components/ButtonAction.vue';

  export default {
    name: 'Surveys_DateSelectors',
    components: {
      ButtonAction,
    },
    data: () => ({
      intervals: ['day', 'week', 'month', 'custom'],
    }),
    computed: {
      ...mapGetters('surveys', ['selectedInterval']),
    },
    methods: {
      ...mapActions('surveys', ['changeInterval']),
    },
  };
</script>

<style scoped lang="scss">
  .surveys-date-selectors {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;

    &-button {
      margin: 10px;
    }
  }
</style>
