<template>
  <div class="surveys-buckets">
    <div
      v-for="bucket in buckets"
      :key="bucket.attribution"
      class="surveys-buckets-item"
      :class="{'surveys-buckets-item--active':isBucketFilterActive(bucket) }"
      @click="toggleFilter(bucket)">
      <font-awesome-icon class="surveys-buckets-item-icon" :icon="bucket.logo" />
      <div class="surveys-buckets-item-box">
        <div>{{ bucket.label }} </div>
        <div><strong>{{ bucket.count }}</strong> </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import attributions from './data/attributions';

  export default {
    name: 'Surveys_SummaryBuckets',
    data: () => ({
      attributions,
    }),
    computed: {
      ...mapGetters('surveys', ['surveys', 'isLoading', 'isBucketFilterActive']),
      unknownSurveys() {
        return this.surveys
          .filter((s) => s.attribution === 'Unknown' || s.attribution === null);
      },
      attributedSurveys() {
        return this.surveys
          .filter((s) => s.attribution !== 'Unknown' && s.attribution !== null);
      },
      buckets() {
        return this.attributions
          .filter((attribution) => attribution.key !== 'Unknown' && attribution.key !== null)
          .map((attribution) => ({
            ...attribution,
            count: this.countAttribution(attribution),
            extra: this.computeExtra(attribution),
          })).sort((a, b) => b.count - a.count);
      },
      totalExtra() {
        return this.buckets.reduce((acc, bucket) => acc + bucket.extra, 0);
      },
    },
    methods: {
      ...mapMutations('surveys', { toggleFilter: 'TOGGLE_BUCKET_FILTER' }),
      computeExtra(attribution) {
        return Math.floor((this.countAttribution(attribution) / this.attributedSurveys.length) * this.unknownSurveys.length);
      },
      countAttribution(attribution) {
        return this.attributedSurveys.filter((s) => s.attribution === attribution.key).length;
      },

    },
  };
</script>

<style scoped lang="scss">
  .surveys-buckets {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 900px;
    margin: 0 auto;

    &-item {
      cursor: pointer;
      margin: 5px;
      padding: 10px;
      width: 160px;
      background-color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: $container-radius;
      &--active {
        border: 2px solid $color-blue;
      }
      &-icon {
        flex: 0 0 30px;
        font-size: 20px;
      }

      &-box {
        flex: 1 1 auto;
      }
    }
  }
</style>
