<template>
  <div class="surveys">
    <SurveysDate />
    <SurveysSummary />
    <SurveysSpinner v-show="isLoading" />
    <SurveysTable v-show="!isLoading" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import SurveysDate from './Surveys_Date.vue';
  import SurveysTable from './Surveys_Table.vue';
  import SurveysSummary from './Surveys_Summary.vue';
  import SurveysSpinner from './Surveys_Spinner.vue';

  export default {
    name: 'Surveys',
    components: {
      SurveysDate,
      SurveysTable,
      SurveysSummary,
      SurveysSpinner,
    },
    computed: {
      ...mapGetters('surveys', ['isLoading']),
    },
    methods: {
      ...mapActions('surveys', ['fetchByDate']),
    },
    created() {
      this.fetchByDate();
    },
  };
</script>

<style scoped lang="scss">
  .surveys {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
  }

</style>
