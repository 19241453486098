<template>
  <div class="surveys-summary">
    <SurveysSummaryStats />
    <SurveysSummaryBuckets />
  </div>
</template>

<script>
  import SurveysSummaryStats from './Surveys_SummaryStats.vue';
  import SurveysSummaryBuckets from './Surveys_SummaryBuckets.vue';

  export default {
    name: 'Surveys_Summary',
    components: {
      SurveysSummaryStats,
      SurveysSummaryBuckets,
    },
  };
</script>

<style scoped lang="scss">
  .surveys-summary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1100px;
    margin: 30px auto;
  }
</style>
