<template>
  <tr class="surveys-table-header-row">
    <th v-if="multiSelectEnabled" class="surveys-table-data-cell" />
    <th
      v-for="col in cols"
      :key="col.key"
      class="surveys-table-header-cell"
      :class="`surveys-table-header-cell--${col.key}`"
      @click="setOrderBy(col.key)">
      {{ col.label }}
      <font-awesome-icon
        v-if="col.key === orderBy"
        :class="orderDirectionChevronClass"
        :icon="['fa', 'chevron-down']" />
    </th>
    <th class="surveys-table-data-cell" />
    <th class="surveys-table-data-cell" />
  </tr>
</template>

<script>
  export default {
    name: 'Surveys_TableHeader',
    props: {
      cols: {
        type: Array,
        default: () => ([]),
      },
      multiSelectEnabled: Boolean,
      orderBy: {
        type: String,
        default: 'orderNumber',
      },
      orderDirection: {
        type: String,
        default: 'asc',
      },
    },
    data: () => ({
      allRows: true,
      onlyConflicts: false,
      onlySelected: false,
    }),
    computed: {
      orderDirectionChevronClass() {
        return { 'surveys-table-header-cell-icon--rotated': this.orderDirection === 'desc' };
      },

    },

    methods: {
      setOrderBy(key) {
        if (this.orderBy === key) {
          const orderDirection = this.orderDirection === 'asc' ? 'desc' : 'asc';
          this.$emit('update:orderDirection', orderDirection);
        } else {
          this.$emit('update:orderBy', key);
          this.$emit('update:orderDirection', 'asc');
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .surveys-table-header {
    width: 100%;

    &-row {
      background-color: $color-blue;
      color: white;
      border: none;
    }

    &-cell {
      border: none;
      cursor: pointer;
      padding: 5px;
    }

    &-cell:not(:last-child) {
      border-right: 2px solid white;
    }
    &-cell-icon--rotated {
      transform: rotate(180deg);
    }
  }

</style>
