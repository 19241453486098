<template>
  <div class="surveys-date">
    <SurveysDateNav />
    <SurveysDateSelectors />
  </div>
</template>

<script>
  import SurveysDateSelectors from './Surveys_DateSelectors.vue';
  import SurveysDateNav from './Surveys_DateNav.vue';

  export default {
    name: 'Surveys_Date',
    components: {
      SurveysDateSelectors,
      SurveysDateNav,
    },
  };
</script>

<style scoped lang="scss">
  .surveys-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
</style>
