export default [
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Order',
    key: 'orderNumber',
  },
  {
    label: 'Value',
    key: 'value',
  },
  {
    label: 'Details',
    key: 'details',
  },
  {
    label: 'Attribution',
    key: 'attribution',
  },
];
