<template>
  <div class="surveys-table">
    <div class="surveys-table-preheader">
      <h3 class="surveys-table-preheader-item">
        Show Only Conflicts
        <input
          v-model="onlyConflicts"
          type="checkbox">
      </h3>
      <h3
        v-if="multiSelectEnabled"
        class="surveys-table-preheader-item">
        Show selected
        <input
          v-model="onlySelected"
          type="checkbox">
      </h3>
    </div>
    <table class="surveys-table-body">
      <SurveysTableHeader
        :cols="cols"
        :multi-select-enabled="multiSelectEnabled"
        :order-by.sync="orderBy"
        :order-direction.sync="orderDirection" />
      <tr
        v-for="survey in displayedRows"
        :key="survey.id"
        class="surveys-table-data-row">
        <td v-if="multiSelectEnabled" class="surveys-table-data-cell--select">
          <a class="surveys-table-data-select" @click="toggleSelected(survey.id)">
            <font-awesome-icon v-if="selectedRows.includes(survey.id)" :icon="['fa', 'check-square']" />
            <font-awesome-icon v-else :icon="['fa', 'square']" />
          </a>
        </td>
        <td
          v-for="col in cols"
          :key="col.key"
          class="surveys-table-data-cell"
          :class="`surveys-table-data-cell--${col.key}`">
          {{ survey[col.key] }}
        </td>
        <td class="surveys-table-data-cell surveys-table-data-cell--edit">
          <span :class="{ 'surveys-table-data-cell--check' : isReviewed(survey)}">
            <font-awesome-icon v-if="isReviewed(survey)" :icon="['fas', 'check-circle']" /> &nbsp;
          </span>
          <a>
            <font-awesome-icon v-if="isConflict(survey)" :icon="['fa', 'exclamation-triangle']" /> &nbsp;
          </a>
        </td>
        <td class="surveys-table-data-cell surveys-table-data-cell--edit" @click="updateAttribution(survey)">
          <a class="surveys-table-data-cell-edit">
            <font-awesome-icon :icon="['fa', 'edit']" />
          </a>
        </td>
      </tr>
    </table>
    <a v-if="surveys.length > 50 && !allRows" @click="allRows = true"> Load more </a>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import SurveysModal from './Surveys_Modal.vue';
  import SurveysTableHeader from './Surveys_TableHeader.vue';
  import surveysColumns from './data/surveys_columns';

  export default {
    name: 'Surveys_Table',
    components: {
      SurveysTableHeader,
    },
    data: () => ({
      allRows: true,
      onlyConflicts: false,
      onlySelected: false,
      orderBy: 'orderNumber',
      orderDirection: 'asc',
      selectedRows: [],
      cols: surveysColumns,
      multiSelectEnabled: false,
    }),

    computed: {
      ...mapGetters('surveys', ['surveys', 'isLoading', 'bucketFilters']),
      surveysRows() {
        let rows = this.surveys;

        if (this.onlyConflicts) {
          rows = rows.filter(this.isConflict);
        }

        if (this.onlySelected) {
          rows = rows.filter(this.isSelected);
        }

        if (this.bucketFilters.length > 0) {
          rows = rows.filter((row) => !!this.bucketFilters.find((filter) => filter === row.attribution));
        }

        if (this.orderBy) {
          rows = rows.sort(this.compareValues(this.orderBy, this.orderDirection));
        }

        rows = rows.map((survey) => ({
          ...survey,
        }));
        return rows;
      },
      displayedRows() {
        return this.allRows ? this.surveysRows : this.surveysRows.slice(0, 40);
      },
    },

    methods: {
      ...mapActions('surveys', ['fetchByDate']),
      toggleSelected(id) {
        const selected = this.selectedRows.includes(id);
        if (selected) {
          this.selectedRows = this.selectedRows.filter((rowId) => id !== rowId);
        } else {
          this.selectedRows.push(id);
        }
      },
      compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
          const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key] || '~';
          const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key] || '~';

          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order === 'desc') ? (comparison * -1) : comparison
          );
        };
      },
      setOrderBy(key) {
        if (this.orderBy === key) {
          this.orderDirection = this.orderDirection === 'asc' ? 'desc' : 'asc';
        } else {
          this.orderBy = key;
          this.orderDirection = 'asc';
        }
      },
      isConflict(survey) {
        return !survey.attribution || survey.attribution === 'Unknown';
      },
      isReviewed(survey) {
        return survey.status === 'reviewed';
      },
      isSelected(survey) {
        return this.selectedRows.includes(survey.id);
      },
      updateAttribution(survey) {
        this.$modal.show(SurveysModal, {
          attribution: survey.attribution,
          externalId: survey.externalId,
        }, {
          height: 'auto',
          scrollable: true,
        }, {
        });
      },
      loadMore() {
        this.allRows = true;
      },
    },
  };
</script>

<style scoped lang="scss">
.surveys-table {
  &-preheader {
    margin: 10px 0;
    display: flex;
    flex-direction: row;

    &-item {
      margin: 10px;
    }
  }

  &-body {
    width: 100%;
    border-spacing:0;
  }

  &-data-select {
    color: $color-blue;
  }

  &-data-row {
    &:nth-child(even) {
      background: white;
    }
  }

  &-data-cell {
    padding: 10px;
    height: 30px;

    &--select {
      width: 30px;
    }

    &--email {
      text-align: left;
      width: 20%;
    }

    &--value {
      text-align: left;
      width: 25%;
    }

    &--details {
      text-align: left;
      width: 25%;
    }

    &--orderNumber {
      width: 80px;
    }

    &--edit {
      text-align: left;
      width: 30px;
    }

    &--conflict {
      text-align: left;
      width: 30px;
    }

    &--check {
      color: green;
    }
  }

  &-data-cell-edit {
    color: $color-blue;
  }
}
</style>
