<template>
  <div class="surveys-spinner">
    <font-awesome-icon
      :icon="['fas', 'circle-notch']"
      spin
      class="surveys-spinner-icon" />
    <span>
      Processing...
    </span>
  </div>
</template>

<script>
  export default {
    name: 'Surveys_Spinner',
  };
</script>

<style scoped lang="scss">
  .surveys-spinner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-icon {
      margin: 10px;
    }

    &-text {
      margin: 10px;
    }
  }
</style>
