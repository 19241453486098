<template>
  <div class="surveys-date-nav">
    <h2 v-if="selectedInterval !== 'custom'" class="surveys-date-nav-title">
      <font-awesome-icon
        :icon="['fas', 'chevron-left']"
        class="surveys-date-nav-icon"
        @click="incrementPeriod" />
      <font-awesome-icon
        class="surveys-date-nav-icon"
        :class="{'surveys-date-nav-icon--disabled': isNextDisabled}"
        :icon="['fas', 'chevron-right']"
        @click="decrementPeriod" />
      {{ displayPeriod }}
    </h2>
    <div
      v-else
      class="surveys-date-nav-custom">
      <v-date-picker
        v-model="range"
        color="red"
        is-range />
    </div>
    <span v-show="surveysToReview" class="surveys-date-nav-complete-icon">
      <font-awesome-icon :icon="['fas', 'check-circle']" /> &nbsp;
    </span>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'Surveys_DateNav',
    computed: {
      ...mapGetters('surveys', ['surveys', 'selectedInterval', 'periodIndex', 'currentPeriod']),
      range: {
        get() {
          return {
            start: new Date(this.currentPeriod.startdate),
            end: new Date(this.currentPeriod.enddate),
          };
        },
        set(value) {
          this.setCustomDates(value);
        },
      },
      surveysToReview() {
        return (this.surveys.filter((survey) => this.isConflict(survey)).length <= 0);
      },
      displayPeriod() {
        const start = this.currentPeriod.startdate;
        const end = this.currentPeriod.enddate;

        if (this.selectedInterval === 'day') {
          return this.currentPeriod.startdate.format('MMM D, YYYY');
        }

        if (start.year() !== end.year()) {
          return `${start.format('MMM D, YYYY')} - ${end.format('MMM D, YYYY')}`;
        }

        if (start.month() !== end.month()) {
          return `${start.format('MMM D')} - ${end.format('MMM D, YYYY')}`;
        }

        return `${start.format('MMM D')} - ${end.format('D, YYYY')}`;
      },
      isNextDisabled() {
        return !this.periodIndex;
      },
    },
    methods: {
      ...mapActions('surveys', ['incrementPeriod', 'decrementPeriod', 'setCustomDates']),
      isConflict(survey) {
        return !survey.attribution || survey.attribution === 'Unknown';
      },
    },
  };
</script>

<style scoped lang="scss">
  .surveys-date-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    &-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0 20px;
    }

    &-custom {
      margin: 0 20px;
      width: 300px;
    }

    &-icon {
      margin: 0 10px;
      font-size: 26px;
      cursor: pointer;

      &--disabled {
        color: $color-dark-gray;
      }
    }

    &-complete-icon {
      color: green;
    }
  }
</style>
