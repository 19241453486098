export default [
  {
    label: 'Paid Social',
    key: 'Paid Social',
    logo: ['fab', 'facebook-f'],
  },
  {
    label: 'Paid Youtube',
    key: 'Paid Youtube',
    logo: ['fab', 'youtube'],
  },
  {
    label: 'Paid Search',
    key: 'Paid Search',
    logo: ['fab', 'google'],
  },
  {
    label: 'AppStore',
    key: 'AppStore',
    logo: ['fab', 'apple'],
  },
  {
    label: 'Organic FB',
    key: 'Organic FB',
    logo: ['fab', 'facebook-f'],
  },
  {
    label: 'Organic Youtube',
    key: 'Organic Youtube',
    logo: ['fab', 'youtube'],
  },
  {
    label: 'Organic Pinterest',
    key: 'Organic Pinterest',
    logo: ['fab', 'pinterest'],
  },
  {
    label: 'Paid Pinterest',
    key: 'Paid Pinterest',
    logo: ['fab', 'pinterest'],
  },
  {
    label: 'Organic TikTok',
    key: 'Organic TikTok',
    logo: ['fab', 'tiktok'],
  },
  {
    label: 'Paid TikTok',
    key: 'Paid TikTok',
    logo: ['fab', 'tiktok'],
  },
  {
    label: 'Organic IG',
    key: 'Organic IG',
    logo: ['fab', 'instagram'],
  },
  {
    label: 'Organic Influencer',
    key: 'Organic Influencer',
    logo: 'female',
  },
  {
    label: 'FC Blog',
    key: 'FC Blog',
    logo: 'blog',
  },
  {
    label: 'Affiliate',
    key: 'Affiliate',
    logo: 'mail-bulk',
  },
  {
    label: 'Retail',
    key: 'Retail',
    logo: 'store',
  },
  {
    label: 'UTM',
    key: 'UTM',
    logo: 'question',
  },
  {
    label: 'Referral',
    key: 'Referral',
    logo: 'users',
  },
  {
    label: 'Organic',
    key: 'Organic',
    logo: 'leaf',
  },
  {
    label: 'PR',
    key: 'PR',
    logo: 'bullhorn',
  },
  {
    label: 'Influencer',
    key: 'Influencer',
    logo: 'star',
  },
  {
    label: 'Mail',
    key: 'Mail',
    logo: 'envelope',
  },
  {
    label: 'Podcast',
    key: 'Podcast',
    logo: 'microphone',
  },
  {
    label: 'Television',
    key: 'Television',
    logo: 'tv',
  },
  {
    label: 'Celebrity',
    key: 'Celebrity',
    logo: 'hand-sparkles',
  },
  {
    label: 'Biz Dev',
    key: 'Biz Dev',
    logo: 'briefcase',
  },
  {
    label: 'Misc',
    key: 'Misc',
    logo: 'dumpster',
  },
  {
    label: 'Unknown',
    key: 'Unknown',
    logo: 'question',
  },
  {
    label: '<empty>',
    key: null,
    logo: 'question',
  },
];
